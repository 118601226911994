import {
  BlobLowerCorner,
  BlobLowerCornerMobile,
  BlobUpperCorner,
  BlobUpperCornerMobile,
} from '@icons/Blobs';
import NextImage from 'components/NextImage';

import { ImagesService } from 'service/images/imagesService';

import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { NextLink } from 'components/NextLink';
import { ReactNode } from 'react';
import type { Banner1 as TBanner1 } from 'server/repository/hygraph/generated/graphqlTypes';
import { LocalizationService } from 'service/localization/localizationService';
import { COLORS } from 'styles/constants';
import type { ISharedTemplateProps, UseExtendedImage } from 'templates/TemplateMapper/types';
import FadeInSection from '../../components/FadeInSection';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { LiquidButton } from '../../components/LiquidButton';
import {
  BackgroundWrapper,
  ButtonMobileStyled,
  ButtonStyled,
  DescriptionText,
  GlobalStyle,
  LowerLeftCornerAsset,
  LowerLeftCornerBlob,
  LowerLeftCornerBlobMobile,
  ProductTitle,
  SuperText,
  TextWrapper,
  TopRightCornerAsset,
  TopRightCornerBlob,
  TopRightCornerBlobMobile,
} from './styles';

const DesktopSVG = ({ backgroundAsset, id }: { backgroundAsset: string; id: string }) => (
  <svg
    viewBox="0 0 952 354"
    preserveAspectRatio="none"
    height="100%"
    width="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id={`${id}-desktop-mask`}>
        <path
          d="M0 30.3502V338C0 346.837 7.16344 354 16 354H936C944.837 354 952 346.837 952 338V30.3502C952 30.3502 737.139 0 477.983 0C218.828 0 0 30.3502 0 30.3502Z"
          fill="#FA85BD"
        />{' '}
      </clipPath>
    </defs>
    <image
      height="354"
      width="952"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        backgroundAsset ?? '',
        70,
        952,
        undefined,
        false,
      )}
      clipPath={`url(#${id}-desktop-mask)`}
    />
  </svg>
);

const MobileSVG = ({ backgroundAsset, id }: { backgroundAsset: string; id: string }) => (
  <svg
    viewBox="0 0 276 460"
    preserveAspectRatio="none"
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <clipPath id={`${id}-mobile-mask`}>
        <path
          d="M0 8.54061V444C0 452.837 7.16344 460 16 460H260C268.837 460 276 452.837 276 444V8.54061C276 8.54061 213.708 0 138.575 0C63.4417 0 0 8.54061 0 8.54061Z"
          fill="#FA85BD"
        />{' '}
      </clipPath>
    </defs>
    <image
      height="460"
      width="276"
      preserveAspectRatio="xMidYMid slice"
      xlinkHref={ImagesService.getImageUrlWithCompressionParams(
        backgroundAsset ?? '',
        70,
        276,
        undefined,
        true,
      )}
      clipPath={`url(#${id}-mobile-mask)`}
    />
  </svg>
);

const metaballs = (upperRightCorner: any, lowerLeftCorner: any) => (
  <>
    <TopRightCornerBlob className="hidden md:block">
      <FadeInSection delay="1200ms">
        <BlobUpperCorner />
      </FadeInSection>
    </TopRightCornerBlob>
    <TopRightCornerBlobMobile className="block md:hidden">
      <FadeInSection delay="1200ms" fadeIn>
        <BlobUpperCornerMobile />
      </FadeInSection>
    </TopRightCornerBlobMobile>
    {upperRightCorner ? (
      <TopRightCornerAsset data-test="top-right-picture">
        <FadeInSection delay="700ms">
          <NextImage
            sources={ImagesService.getPictureSources(upperRightCorner?.[0])}
            alt=""
            width={upperRightCorner?.width}
            height={upperRightCorner?.height}
          />
        </FadeInSection>
      </TopRightCornerAsset>
    ) : null}
    <LowerLeftCornerBlob className="hidden md:block">
      <FadeInSection delay="1200ms" bounce fadeIn>
        <BlobLowerCorner />
      </FadeInSection>
    </LowerLeftCornerBlob>
    <LowerLeftCornerBlobMobile className="block md:hidden">
      <FadeInSection delay="1200ms" fadeIn>
        <BlobLowerCornerMobile />
      </FadeInSection>
    </LowerLeftCornerBlobMobile>
    {lowerLeftCorner ? (
      <LowerLeftCornerAsset data-test="lower-left-picture">
        <FadeInSection delay="300ms">
          <NextImage
            alt=""
            sources={ImagesService.getPictureSources(lowerLeftCorner?.[0])}
            width={lowerLeftCorner?.width}
            height={lowerLeftCorner?.height}
          />
        </FadeInSection>
      </LowerLeftCornerAsset>
    ) : null}
  </>
);

export interface IBannerProps extends ISharedTemplateProps {
  templateProps: UseExtendedImage<TBanner1, 'backgroundAsset'>;
  children?: ReactNode | ReactNode[];
}

export const Banner1 = ({ templateProps, primarySubBrandColor, children }: IBannerProps) => {
  const { pathTranslations } = useGlobalContext();
  const localizedLink = {
    url: templateProps.link?.url as string,
    internalLink: {
      ...templateProps.link?.internalLink,
    },
  };
  const linkUrl = LocalizationService.getLocalizedLinkPath({
    item: localizedLink,
    pathTranslations,
  });
  const id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-banner1`;
  const btnHex = primarySubBrandColor
    ? primarySubBrandColor
    : templateProps.buttonColor === 'Light'
      ? COLORS.secondary
      : COLORS.primary;

  return (
    <div className="relative overflow-hidden pt-20 pb-20" data-test="banner1">
      <Grid>
        <GridRow columns={16}>
          <GridItem colSpan={12} colStart={3} className="md:relative">
            {metaballs(templateProps.upperRightCorner?.asset, templateProps.lowerLeftCorner?.asset)}
            <FadeInSection delay="400ms">
              <div className="h-fit hidden md:block">
                <BackgroundWrapper>
                  <DesktopSVG
                    backgroundAsset={templateProps.backgroundAsset?.asset[0]?.background || ''}
                    id={componentName}
                  />
                </BackgroundWrapper>
              </div>
              <div className="block md:hidden">
                <BackgroundWrapper>
                  <MobileSVG
                    backgroundAsset={templateProps.backgroundAsset?.asset[0]?.background || ''}
                    id={componentName}
                  />
                </BackgroundWrapper>
              </div>
              <GlobalStyle />
              <TextWrapper>
                <FadeInSection delay="300ms" fadeIn>
                  {templateProps.superText && (
                    <SuperText tag="p" type="lead" data-test="banner1-super-text">
                      {templateProps.superText}
                    </SuperText>
                  )}
                  {templateProps.title && (
                    <ProductTitle tag="h3" type="sm" data-test="banner1-title">
                      {templateProps.title}
                    </ProductTitle>
                  )}
                  {templateProps.description && (
                    <DescriptionText tag="p" type="md" data-test="banner1-description">
                      {templateProps.description}
                    </DescriptionText>
                  )}
                  {linkUrl && (
                    <NextLink href={linkUrl ?? ''} className="inline-block">
                      <span className="sr-only">{templateProps.link?.displayName}</span>
                      <ButtonStyled>
                        <LiquidButton
                          text={templateProps.link?.displayName ?? ''}
                          height={50}
                          width={250}
                          color={btnHex}
                          textColor={
                            templateProps.buttonColor === 'Light' ? COLORS.black : COLORS.white
                          }
                        />
                      </ButtonStyled>
                      <ButtonMobileStyled>
                        <LiquidButton
                          text={templateProps.link?.displayName ?? ''}
                          height={50}
                          width={200}
                          color={btnHex}
                          textColor={
                            templateProps.buttonColor === 'Light' ? COLORS.black : COLORS.white
                          }
                        />
                      </ButtonMobileStyled>
                    </NextLink>
                  )}
                  {children}
                  {!linkUrl && <div className="pb-20" />}
                </FadeInSection>
              </TextWrapper>
            </FadeInSection>
          </GridItem>
        </GridRow>
      </Grid>
    </div>
  );
};
