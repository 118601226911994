import tw from 'tailwind-styled-components';
import styled, { createGlobalStyle } from 'styled-components';
import { Title, Text as Paragraph } from '../../components/Typography';

export const GlobalStyle = createGlobalStyle`
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    focus:outline-none;
    display:flex;
  }
`;
GlobalStyle.displayName = 'GlobalStyle';

export const BackgroundWrapper = tw.div`
  absolute
  h-full
  w-full
  min-h-354
`;
BackgroundWrapper.displayName = 'BackgroundWrapper';

export const TextWrapper = tw.div`
  text-center
  pt-20
  md:pt-10
  px-4
  md:px-28
  lg:px-32
  md:mb-20
  sm:mb-10
  min-h-354
  flex
  flex-col
  align-center
  justify-center
`;
TextWrapper.displayName = 'TextWrapper';

export const TopRightCornerBlob = styled.div`
  position: absolute;
  right: -20%;
  top: 0;
  z-index: -1;

  @media screen and (max-width: 767px) {
    right: 0%;
    top: 1%;
  }
`;
TopRightCornerBlob.displayName = 'TopRightCornerBlob';

export const TopRightCornerBlobMobile = styled.div`
  position: absolute;
  z-index: -1;

  @media screen and (max-width: 767px) {
    right: 0%;
    top: 1%;
  }
`;
TopRightCornerBlob.displayName = 'TopRightCornerBlob';

export const TopRightCornerAsset = styled.div`
  position: absolute;
  top: 7%;
  z-index: 1;
  right: -10%;
  width: 40%;
  max-width: 286px;
  max-height: 286px;
  @media screen and (min-width: 768px) {
    top: 7%;
    right: -20%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
TopRightCornerAsset.displayName = 'TopRightCornerAsset';

export const LowerLeftCornerBlob = styled.div`
  position: absolute;
  left: -20%;
  bottom: 45px;
  z-index: -1;
`;
LowerLeftCornerBlob.displayName = 'LowerLeftCornerBlob';

export const LowerLeftCornerBlobMobile = styled.div`
  position: absolute;
  z-index: -1;
  left: -20px;
  bottom: 100px;
`;
LowerLeftCornerBlobMobile.displayName = 'TopRightCornerBlob';

export const LowerLeftCornerAsset = styled.div`
  position: absolute;
  left: 0;
  bottom: 4%;
  z-index: 1;
  width: 40%;
  max-width: 286px;
  max-height: 286px;
  @media screen and (min-width: 768px) {
    left: -20%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
LowerLeftCornerAsset.displayName = 'LowerLeftCornerAsset';

export const SuperText = tw(Paragraph)`
  mb-2
  text-darkgray
  opacity-70
  pt-0.5
`;
SuperText.displayName = 'SuperText';

export const DescriptionText = tw(Paragraph)`
 md:-mb-8
`;
DescriptionText.displayName = 'DescriptionText';

export const ProductTitle = tw(Title)`
  mb-2.5
`;
ProductTitle.displayName = 'ProductTitle';

export const ButtonStyled = styled.div`
  display: block;
  @media (max-width: 640px) {
    display: none;
  }
`;
ButtonStyled.displayName = 'ButtonStyled';

export const ButtonMobileStyled = styled.div`
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
`;
ButtonMobileStyled.displayName = 'ButtonMobileStyled';
